import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  BuscaNfeContextDataProps,
  BuscaNfeContextProviderProps,
  ItensProps,
  NfItensProps,
  ResquestProduto,
  NfSelecionada,
} from './protocols';
import { schema } from './validations';
import moment from 'moment';
import { toast } from 'react-toastify';
import api from '~/services/api';
import Swal from 'sweetalert2';
import { getUserData } from '~/services/user';
import {
  formatCurrencyAsText,
  moneyFormat,
  transformAsCurrency,
} from '~/utils/functions';
import { variacao } from './utils/variacao';
import { LojaContext } from '~/context/loja';
import { showToleraConfirmationDialog } from './utils/showToleraConfirmationDialog';
import { toleraValor } from './utils/toleraValor';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

export const BuscaNfeContext = createContext({} as BuscaNfeContextDataProps);

export function BuscaNfeContextProvider({
  codLoja,
  fornecedorOption,
  numNF,
  quantityItems,
  reset: resetTela,
  children,
  setNfData,
  disabled = false,
}: BuscaNfeContextProviderProps) {
  const user = getUserData();

  const { loja: lojaContext } = useContext(LojaContext);

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [loja, setLoja] = useState<number | undefined>(codLoja);
  const [fornecedor, setFornecedor] = useState<any>({
    label: fornecedorOption?.label,
    value: fornecedorOption?.value,
    des_uf: '',
  });

  const [tipo_periodo, setTipo_periodo] = useState<number>(1);
  const [isEmptyLineMessage, setIsEmptyLineMessage] = useState<boolean>(false);
  const [showNFs, setShowNFs] = useState<boolean>(false);
  const [nf, setNf] = useState<ItensProps>({} as ItensProps);
  const [nfs, setNfs] = useState<ItensProps[]>([]);
  const [nfItens, setNfItens] = useState<NfItensProps[]>([]);
  const [nfXMLItens, setNFXMLItens] = useState<any>();
  const [codXml, setCodXml] = useState<number | undefined>(undefined);
  const [disable, setDisabled] = useState<boolean>(false);
  const [buscaDisabled, setBuscaDisabled] = useState<boolean>(disabled);
  const [loading, setLoading] = useState<string>('');

  const [nfSelecionada, setNFSelecionada] = useState<NfSelecionada>({
    cod_xml: 0,
    num_nf: 0,
  });

  useEffect(() => {
    setDisabled(nfs.length > 0);
  }, [nfs]);

  useEffect(() => {
    ClearInputs();
  }, [resetTela]);

  const {
    register: registerItems,
    handleSubmit: handleSubmitItems,
    control: controlItems,
    reset: resetItems,
    getValues: getValuesItems,
    setValue: setValueItems,
    setError: setErrorItems,
    clearErrors: clearErrorsItems,
    setFocus: setFocusItems,
    formState: formStateItems,
  } = useForm({
    reValidateMode: 'onBlur',
  });

  const formItens = {
    register: registerItems,
    handleSubmit: handleSubmitItems,
    control: controlItems,
    reset: resetItems,
    getValues: getValuesItems,
    setValue: setValueItems,
    setError: setErrorItems,
    clearErrors: clearErrorsItems,
    setFocus: setFocusItems,
    formState: formStateItems,
  };

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    setError,
    getValues,
    clearErrors,
    formState: { isDirty, errors },
    setFocus,
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  function validationsSearch(data: FieldValues): boolean {
    let isValidated = true;
    const isDate = !(data.dta_de === '' || data.dta_ate === '');
    if (isDate) {
      const dtaDe = moment(data.dta_de);
      const dtaAte = moment(data.dta_ate);
      const duration = moment.duration(dtaAte.diff(dtaDe)).asDays();
      if (duration >= 365) {
        setError('dta_de', { type: 'focus' });
        setError('dta_ate', { type: 'focus' });
        toast.warning('Informe um período de pesquisa menor');
        isValidated = false;
      }
    }

    const hasFornecedor = typeof data.busca_parceiro.value !== 'undefined';
    const hasDtaAte = !(data.dta_ate === '');
    const hasDtaDe = !(data.dta_de === '');
    const hasNumNF = !(data.num_nf === '');

    if (isValidated) {
      isValidated = hasFornecedor || hasDtaAte || hasDtaDe || hasNumNF;

      if (!isValidated)
        toast.warning('Informe pelo menos um critério para a busca!');
    }

    if (!isValidated) setLoading('');
    return isValidated;
  }

  const handleSearch = handleSubmit(async (data) => {
    try {
      setLoading('pesquisar');
      if (!validationsSearch(data)) return;
      const result = await api.get('/nf/busca-xml', {
        params: {
          cod_loja: data.cod_loja,
          cod_pessoa: data.busca_parceiro.value || 0,
          dta_de: data.dta_de,
          dta_ate: data.dta_ate,
          num_nf: data.num_nf.replace('.', '') || 0,
          tipo_periodo,
        },
      });
      if (result.data.success) {
        if (result.data.data.length <= 0) {
          toast.warn(
            'Nenhum registro encontrado conforme os critérios informados.',
          );
          setLoading('');
          return;
        }
        setNfs(result.data.data);
        setNfItens([]);
        setNFSelecionada({
          cod_xml: 0,
          num_nf: 0,
        });
        setIsEmptyLineMessage(true);
      } else {
        if (result.data.data.length <= 0) {
          toast.warn(
            'Houve um problema ao consultar os registros. Favor contate o suporte.',
          );
          setLoading('');
          return;
        }
        setNfs([]);
        setNfItens([]);
        setNFSelecionada({
          cod_xml: 0,
          num_nf: 0,
        });
        setLoading('');
      }
      setTimeout(() => {
        setLoading('');
      }, 700);
    } catch (error) {
      setLoading('');
    }
  });

  function handleCancel() {
    setDisabled(false);
    setNfs([]);
    setNfItens([]);
    setLoading('');
    setIsEmptyLineMessage(false);
    setNFSelecionada({
      cod_xml: 0,
      num_nf: 0,
    });
    setCodXml(undefined);
  }

  async function getXMLItem({ num_item, itens, onlyQtd, flg_ipv }: any) {
    const xml: any = itens.find(
      (i: any) => Number(i.nItem) === Number(num_item),
    );
    let vUnidade: string = xml.uCom; // unidade
    let vQtde: string = xml.qCom; // quantidade
    let vValUn: string = xml.vUnCom; // valor

    if (flg_ipv && xml.uCom !== 'KG' && xml.uTrib === 'KG') {
      vUnidade = xml.uTrib;
      vQtde = xml.qTrib;
      vValUn = xml.vUnTrib;
    }

    let vEmb: string = vUnidade.toUpperCase().substring(0, 2);
    if (vUnidade.toUpperCase().substring(0, 3) === 'MIL') {
      vEmb = 'ML';
    }
    if (onlyQtd) {
      return { vUnidade, vQtde, vValUn, vEmb };
    }
    return { ...xml, vUnidade, vQtde, vValUn, vEmb };
  }

  /**
   * Função recursiva para encontrar a chave em qualquer nivel do objeto
   */
  const findInObj = useCallback((obj: any, chave: string): any => {
    if (Object.prototype.hasOwnProperty.call(obj, chave)) {
      return obj[chave];
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const prop in obj) {
      if (typeof obj[prop] === 'object') {
        const result = findInObj(obj[prop], chave);
        if (result) {
          return result;
        }
      }
    }
    return '';
  }, []);

  const calcCustoRepNF = useCallback(
    ({
      val_peso,
      vUnidade,
      vQtde,
      qtd_embalagem,
      xmlItem,
    }: {
      val_peso: number;
      vUnidade: string;
      vQtde: number;
      qtd_embalagem: number;
      xmlItem: any;
    }) => {
      let vPeso: number;
      if (
        vUnidade !== 'KG' &&
        vUnidade !== 'ML' &&
        vUnidade !== 'MIL' &&
        vUnidade !== 'MI'
      ) {
        vPeso = 1;
      } else {
        vPeso = val_peso === 0 ? 1 : val_peso;
      }

      const qtdTotal = (qtd_embalagem * vQtde) / vPeso;

      const vProd = findInObj(xmlItem, 'vProd');
      const vICMSST = findInObj(xmlItem, 'vICMSST');
      const vFCPST = findInObj(xmlItem, 'vFCPST');
      const vIPI = findInObj(xmlItem, 'vIPI');
      const vFrete = findInObj(xmlItem, 'vFrete');
      const vDesc = findInObj(xmlItem, 'vDesc');
      const vOutro = findInObj(xmlItem, 'vOutro');

      const custoRepNF =
        (Number(vProd) +
          Number(vICMSST) +
          Number(vFCPST) +
          Number(vIPI) +
          Number(vFrete) -
          Number(vDesc) +
          Number(vOutro)) /
        qtdTotal;

      return custoRepNF;
    },
    [findInObj],
  );

  const getNFItens = useCallback(
    async (cod_xml: number, nfToSearch: any) => {
      try {
        setLoading('items');
        setCodXml(cod_xml);

        const promise = api.get(`/nf/busca-xml/${cod_xml}`);
        const [result] = await Promise.all([promise]);

        if (result.data.success && result.data.data.length > 0) {
          // Pegar dados do XML
          const promiseXML = importarXML(
            nfToSearch.cod_loja,
            nfToSearch.num_chave_acesso,
          );
          const [XML] = await Promise.all([promiseXML]);

          if (XML?.nf?.nNF === undefined) {
            throw Error('arquivo de XML não encontrado');
          }
          setNFXMLItens(XML);
          const nfitens = result.data.data;

          const items: any = [];
          // eslint-disable-next-line no-restricted-syntax
          for await (const nfitem of nfitens) {
            const xmlItem = XML.itens[0].find(
              (item: any) => Number(item.nItem) === Number(nfitem.num_item),
            );
            const { vQtde, vValUn, vUnidade } = await getXMLItem({
              num_item: nfitem.num_item,
              itens: XML.itens[0],
              onlyQtd: true,
              flg_ipv: nfitem.flg_ipv,
            });

            const custoRepNF = calcCustoRepNF({
              val_peso: nfitem.val_peso,
              vUnidade,
              vQtde,
              qtd_embalagem: nfitem.qtd_embalagem,
              xmlItem,
            });

            const variacaoProduto = variacao(nfitem.val_custo_rep, custoRepNF);

            items.push({
              ...nfitem,
              qtd_item: Number(vQtde),
              qtd_total: Number(vQtde) * Number(nfitem.qtd_embalagem),
              val_total_item: moneyFormat(String(vValUn * vQtde), 2),
              val_custo_rep: formatCurrencyAsText(nfitem.val_custo_rep),
              val_custo_rep_nf: custoRepNF,
              variacao_percent: formatCurrencyAsText(
                variacaoProduto === Infinity ? 0 : variacaoProduto,
              ),
            });
          }
          setNfItens(items);
        }
        setLoading('');
      } catch (error) {
        setLoading('');
        console.error(error);
      }
    },
    [calcCustoRepNF],
  );

  const ClearInputs = useCallback(async () => {
    setNf({} as any);
    setNfs([]);
    setNfItens([]);

    setIsEmptyLineMessage(false);
    setTipo_periodo(1);
    reset();
    // cod loja
    if (codLoja) {
      setValue('cod_loja', codLoja);
      setLoja(codLoja);
    } else {
      setLoja(user.loja);
    }
    // num nf
    if (numNF) {
      setValue('num_nf', numNF);
    } else {
      setValue('num_nf', ' ');
    }
    // fornecedor
    if (fornecedorOption) {
      setValue('busca_parceiro', {
        label: fornecedorOption?.label,
        value: fornecedorOption?.value,
        des_uf: fornecedorOption?.des_uf,
      });
      setFornecedor({
        label: fornecedorOption?.label,
        value: fornecedorOption?.value,
        des_uf: fornecedorOption?.des_uf,
      });
    } else {
      setValue('busca_parceiro', '');
      setFornecedor({
        label: '',
        value: undefined,
      });
    }
    setValue('dta_de', '');
    setValue('dta_ate', '');
    setLoading('');
    setCodXml(undefined);
  }, [reset, codLoja, fornecedorOption, setValue, user]);

  async function importarItensNF(cod_xml: number) {
    const promise = api.get(`/nf/busca-xml/${cod_xml}`);
    const [res] = await Promise.all([promise]);
    if (!res.data.success) {
      return res.data;
    }
    return res.data.data;
  }

  async function importarXML(cod_loja: number, num_chave_acesso: string) {
    try {
      const promise = api.post(`/nf/buscar-arquivo-xml`, {
        cod_loja,
        num_chave_acesso,
        filename: 'nfe-proc.json',
      });
      const [res] = await Promise.all([promise]);
      if (!res.data.success) {
        toast.warning('arquivo xml não importado');
      }
      return res.data.data;
    } catch (error) {
      console.error(error);
    }
  }

  function buscarValor(obj: any, campo: string): number {
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const chave in obj) {
      if (obj[chave][campo] !== undefined) {
        return Number(obj[chave][campo]);
      }
    }
    return 0;
  }

  function CreateTable(itens: any[]): void {
    const AlertTableHeader = '<tr><th>Código</th><th>Descrição</th></tr>';
    const AlertTableBody = itens.map((item: any): string => {
      const spaco = '&nbsp;&nbsp;';
      return `<tr><td>${item.cod_produto}${spaco}</td><td>${item.des_produto}${spaco}</td></tr>`;
    });

    const AlertTable = `
            <div style='max-height: 200px;white-space: nowrap; border: solid 1px #dcdcdc; overflow: auto'>
              <table style='width:100%;min-width: 500px'>
                <thead>${AlertTableHeader}</thead>
                <tbody>${AlertTableBody.join(' ')}</tbody>
              </table>
            </div>
            <p style='text-align: left; padding: 10px'>
            </p>
            `;
    MySwal.fire({
      icon: 'warning',
      width: 800,
      title: 'Produto(s) não IPV com quantidade fracionada.',
      html: String(AlertTable),
    });
  }

  const getNF = handleSubmitItems(async (formData) => {
    try {
      setLoading('selecionar');
      const invalidItems = Object.values(formData).some(
        (item) => transformAsCurrency(item) <= 0,
      );

      if (invalidItems) {
        toast.warning(
          'A quantidade por embalagem dos itens deve ser maior que zero.',
        );
        return;
      }
      if (nf?.num_nf === undefined) {
        return toast.warning('XML Validado deve ser pesquisado e selecionado.');
      }
      const nfitens = await importarItensNF(nf.cod_xml);

      const findMatchingValueFromFormData = (
        cod_produto: string,
        num_item: number,
      ): number | undefined => {
        const keySuffix = `_${cod_produto}_${num_item}`;
        const matchingKey = Object.keys(formData).find((key) =>
          key.endsWith(keySuffix),
        );
        if (matchingKey !== undefined) {
          return formData[matchingKey];
        }
        return undefined;
      };

      // Atualizar a qtd_embalagem com base nos valores de formData
      nfitens.forEach((produto: any) => {
        const value = findMatchingValueFromFormData(
          produto.cod_produto,
          produto.num_item,
        );

        if (value !== undefined) {
          produto.qtd_embalagem = transformAsCurrency(value);
        }

        // Valida se algum produto não IPV contem quantidade fracionada

        produto.invalid_ipv_qtd = false;
        if (
          produto.flg_ipv === false &&
          !Number.isInteger(produto.qtd_embalagem)
        ) {
          produto.invalid_ipv_qtd = true;
        }
      });

      const invalidIpvItems = nfitens.filter(
        (item: any) => item.invalid_ipv_qtd,
      );

      if (invalidIpvItems.length > 0) {
        CreateTable(invalidIpvItems);
        return;
      }

      if (nfitens.length === 0) {
        return toast.warning('Não há itens para importação na Nota Fiscal.');
      }

      const XML = await importarXML(nf.cod_loja, nf.num_chave_acesso);

      if (XML?.nf?.nNF === undefined) {
        throw Error('arquivo de XML não encontrado');
      }

      const itens = [];
      let VIcms = 0;
      let BCICms = 0;
      let VIcmsVdaCred = 0;
      let BCICmsVdaCred = 0;

      const itemsExceededPercentage = [];

      // eslint-disable-next-line no-restricted-syntax
      for await (const nfitem of nfitens) {
        const { data }: ResquestProduto = await api.get(
          '/nf/importando-produto',
          {
            params: {
              cod_loja: nf.cod_loja,
              cod_gtin: nfitem.cod_gtin,
              cod_fornecedor: fornecedor.cod_pessoa,
              des_sigla: fornecedor.des_uf,
            },
          },
        );

        if (!data.success) return toast.warning('Produto não encontrado');

        const XMLItem = await getXMLItem({
          num_item: nfitem.num_item,
          itens: XML.itens[0],
          flg_ipv: nfitem.flg_ipv,
        });

        if (!XMLItem) return toast.warning('xml não encontrado');

        const itemFromTable = nfItens.find(
          (item) => item.num_item === nfitem.num_item,
        );

        const { per_tol_custo, per_bloq_custo } = lojaContext.regra_empresa;

        const variacaoProduto = itemFromTable
          ? itemFromTable.variacao_percent
          : 0;

        const toleraTolCusto = toleraValor(
          transformAsCurrency(variacaoProduto),
          per_tol_custo,
        );

        const toleraBloqCusto = toleraValor(
          transformAsCurrency(variacaoProduto),
          per_bloq_custo,
        );

        if (!toleraTolCusto || !toleraBloqCusto) {
          const obj = { ...nfitem, variacao_produto: variacaoProduto };
          itemsExceededPercentage.push(obj);
        }

        const item: any = {
          // NF item
          cod_xml: nfitem.cod_xml,
          cfop: nfitem.cfop.trim(),
          num_item: nfitem.num_item,
          cod_produto: nfitem.cod_produto,
          cod_gtin: nfitem.cod_gtin,
          des_produto: nfitem.des_produto,
          des_unidade: nfitem.des_unidade,
          cod_loja: nf.cod_loja,
          num_chave_acesso: nf.num_chave_acesso,
          // Produto
          cod_ncm: data.data[0].cod_ncm,
          num_ncm: data.data[0].num_ncm,
          flg_ipv: data.data[0].flg_ipv,
          cod_gtin_principal: data.data[0].cod_gtin_principal,
          qtd_est_atual: data.data[0].qtd_est_atual,
          flg_nao_pis_cofins: data.data[0].flg_nao_pis_cofins,
          val_custo_tabela: data.data[0].val_custo_tabela,
          val_margem: data.data[0].val_margem,
          val_custo_rep: data.data[0].val_custo_rep,
          cod_trib_saida: data.data[0].cod_trib_saida,
          tipo_trib_saida: data.data[0].tipo_trib_saida,
          cod_sit_tributaria_saida: data.data[0].cod_sit_tributaria_saida,
          val_icms_saida: data.data[0].val_icms_saida,
          val_reducao_saida: data.data[0].val_reducao_saida,
          des_trib_saida: data.data[0].des_trib_saida,
          cod_trib_entrada: data.data[0].cod_trib_entrada,
          tipo_trib_ent: data.data[0].tipo_trib_ent,
          cod_sit_tributaria_ent: data.data[0].cod_sit_tributaria_ent,
          val_icms_ent: data.data[0].val_icms_ent,
          val_reducao_ent: data.data[0].val_reducao_ent,
          des_trib_ent: data.data[0].des_trib_ent,
          flg_veda_cred: data.data[0].flg_veda_cred,
          flg_veda_cred_lim: data.data[0].flg_veda_cred_lim,
          per_veda_cred_lim: data.data[0].per_veda_cred_lim,
          per_pauta_iva: data.data[0].per_pauta_iva,
          val_pauta_iva: data.data[0].val_pauta_iva,
          val_pauta_ipv: data.data[0].val_pauta_ipv,
          des_unidade_compra: data.data[0].des_unidade_compra,
          flg_fora_linha: data.data[0].flg_fora_linha,
          val_peso: data.data[0].val_peso,
          cod_id_ctb: data.data[0].cod_id_ctb,
          des_referencia: data.data[0].des_referencia,
          flg_fabricante: data.data[0].flg_fabricante,
          qtd_unid_compra_forn: data.data[0].qtd_unid_compra_forn,
          des_unid_compra_forn: data.data[0].des_unid_compra_forn,
          qtd_embalagem_compra: data.data[0].qtd_embalagem_compra,
          flg_ignora_pis_cofins: data.data[0].flg_ignora_pis_cofins,
          tipo_movimentacao: data.data[0].tipo_movimentacao,
          tipo_explosao_compra: data.data[0].tipo_explosao_compra,
          tipo_forma_explosao: data.data[0].tipo_forma_explosao,
          // xml
          flg_bonificado: !!(
            Number(nfitem.cfop) === 910 || Number(nfitem.cfop) === 920
          ),
          qtd_embalagem: Number(nfitem.qtd_embalagem),
          qtd_entrada: Number(XMLItem.vQtde),
          des_embalagem: XMLItem?.vEmb,
          val_unitario: Number(XMLItem?.vValUn),
          val_desconto: Number(
            XMLItem?.vDesc === undefined ? 0 : XMLItem?.vDesc,
          ), // não existe em itens
          val_despesa_acessoria: Number(
            XMLItem?.vOutro === undefined ? 0 : XMLItem?.vOutro,
          ), // não existe em itens
          val_frete: Number(
            XMLItem?.vFrete === undefined ? 0 : XMLItem?.vFrete,
          ), // não existe em itens
          val_ipi: buscarValor(XMLItem?.imposto?.IPI, 'vIPI'),
          val_st_fcp: buscarValor(XMLItem?.imposto?.ICMS, 'vFCPST'),
          val_icms_st: buscarValor(XMLItem?.imposto?.ICMS, 'vICMSST'),
          val_fcp_icms: buscarValor(XMLItem?.imposto?.ICMS, 'vFCPST'),
          val_total: Number(XMLItem?.vProd === undefined ? 0 : XMLItem?.vProd),
          per_aliq_icms_xml: buscarValor(XMLItem?.imposto?.ICMS, 'pICMS'), // não existe em itens
          per_red_bc_icms_xml: buscarValor(XMLItem?.imposto?.ICMS, 'pREDBC'), //  não achei
          cod_sit_tributaria: buscarValor(XMLItem?.imposto?.ICMS, 'CST'),
          // variaveis XML
          val_bc_st_xml: buscarValor(XMLItem?.imposto?.ICMS, 'vBCST'),
          val_icms_st_xml: buscarValor(XMLItem?.imposto?.ICMS, 'vICMSST'),
          val_bc_icms_xml: buscarValor(XMLItem?.imposto?.ICMS, 'vBC'),
          val_icms_xml: buscarValor(XMLItem?.imposto?.ICMS, 'vICMS'),
          val_produto_xml: Number(XMLItem.vProd || 0),
          val_despesa_acessoria_xml: Number(
            XMLItem?.vOutro === undefined ? 0 : XMLItem?.vOutro,
          ),
          val_desconto_xml: Number(
            XMLItem?.vDesc === undefined ? 0 : XMLItem?.vDesc,
          ),
          val_fcp_st_xml: buscarValor(XMLItem?.imposto?.ICMS, 'vFCPST'),
          val_fcp_icms_xml: buscarValor(XMLItem?.imposto?.ICMS, 'vFCP'),
          val_pis_xml: buscarValor(XMLItem?.imposto?.PIS, 'vPIS'),
          val_cofins_xml: buscarValor(XMLItem?.imposto?.COFINS, 'vCOFINS'),
          num_lote_rast: Number(
            XMLItem?.nLote === undefined ? 0 : XMLItem?.nLote,
          ),
          qtd_lote_rast: Number(
            XMLItem?.qLote === undefined ? 0 : XMLItem?.qLote,
          ),
          dta_fabricacao_rast:
            XMLItem?.dFab === undefined ? null : XMLItem?.dFab,
          cod_agreg_rast:
            XMLItem?.cAgreg === undefined ? null : XMLItem?.cAgreg,
          dta_validade: XMLItem?.dVal === undefined ? null : XMLItem?.dVal,
        };
        itens.push(item);

        if (
          data.data[0].flg_veda_cred &&
          Number(
            data.data[0].per_veda_cred_lim === undefined
              ? 0
              : data.data[0].per_veda_cred_lim,
          ) === 0
        ) {
          VIcmsVdaCred += buscarValor(XMLItem?.imposto.ICMS, 'vICMS');
          BCICmsVdaCred += buscarValor(XMLItem?.imposto.ICMS, 'vBC');
        } else if (
          buscarValor(XMLItem?.imposto?.ICMS, 'CST') === 70 ||
          buscarValor(XMLItem?.imposto?.ICMS, 'CST') === 10 ||
          buscarValor(XMLItem?.imposto?.ICMS, 'CST') === 51
        ) {
          VIcms += buscarValor(XMLItem?.imposto?.ICMS, 'vICMS');
          BCICms += buscarValor(XMLItem?.imposto?.ICMS, 'vBC');
        }
      }

      let proceed = true;

      if (itemsExceededPercentage.length > 0 && loja) {
        const isConfirmed = await showToleraConfirmationDialog(
          itemsExceededPercentage,
        );

        if (!isConfirmed) proceed = false;
      }

      if (!proceed) return;

      setNfData({
        nf: {
          ...nf,
          num_nf: XML.nf.nNF,
          dta_emissao: XML.nf.dhEmi,
          natOp: XML?.nf?.natOp || '',
          infCpl: XML?.informacaoAdicional?.infCpl || '',
          vOutro: XML?.total?.ICMSTot?.vOutro || 0,
        },
        itens,
        totais: {
          VIcms,
          BCICms,
          VIcmsVdaCred,
          BCICmsVdaCred,
          vBC: Number(XML.total.ICMSTot.vBC || 0),
          vBCST: Number(XML.total.ICMSTot.vBCST || 0),
          vCOFINS: Number(XML.total.ICMSTot.vCOFINS || 0),
          vDesc: Number(XML.total.ICMSTot.vDesc || 0),
          vFCP: Number(XML.total.ICMSTot.vFCP || 0),
          vFCPST: Number(XML.total.ICMSTot.vFCPST || 0),
          vFCPSTRet: Number(XML.total.ICMSTot.vFCPSTRet || 0),
          vFrete: Number(XML.total.ICMSTot.vFrete || 0),
          vICMS: Number(XML.total.ICMSTot.vICMS || 0),
          vICMSDeson: Number(XML.total.ICMSTot.vICMSDeson || 0),
          vII: Number(XML.total.ICMSTot.vII || 0),
          vIPI: Number(XML.total.ICMSTot.vIPI || 0),
          vIPIDevol: Number(XML.total.ICMSTot.vIPIDevol || 0),
          vNF: Number(XML.total.ICMSTot.vNF || 0),
          vOutro: Number(XML.total.ICMSTot.vOutro || 0),
          vPIS: Number(XML.total.ICMSTot.vPIS || 0),
          vProd: Number(XML.total.ICMSTot.vProd || 0),
          vST: Number(XML.total.ICMSTot.vST || 0),
          vSeg: Number(XML.total.ICMSTot.vSeg || 0),
          vTotTrib: Number(XML.total.ICMSTot.vTotTrib || 0),
        },
      });
      setIsOpenModal(false);
    } catch (error: any) {
      if (error?.status === 404) {
        Swal.fire(
          'Arquivo XML Processado não encontrado!',
          'Refaça o processamento do XML por meio da tela "Processamento Manual de XML" e tente novamente.',
          'warning',
        );
      } else {
        toast.error(String(error));
      }
    } finally {
      setLoading('');
    }
  });

  return (
    <BuscaNfeContext.Provider
      value={{
        isOpenModal,
        setIsOpenModal,
        quantityItems,
        loja,
        setLoja,
        fornecedor,
        setFornecedor,
        tipo_periodo,
        setTipo_periodo,
        isEmptyLineMessage,
        showNFs,
        setShowNFs,
        loading,
        setLoading,
        disable,
        setDisabled,
        register,
        setFocus,
        errors,
        control,
        setValue,
        handleSearch,
        handleCancel,
        setNf,
        nf,
        nfs,
        ClearInputs,
        getNFItens,
        setNfItens,
        nfItens,
        getNF,
        codXml,
        setCodXml,
        fornecedorOption,
        formItens,
        nfSelecionada,
        setNFSelecionada,
        buscaDisabled,
        setBuscaDisabled,
        getValues,
        clearErrors,
        nfXMLItens,
        setNFXMLItens,
        calcCustoRepNF,
      }}
    >
      {children}
    </BuscaNfeContext.Provider>
  );
}

export const useBuscaNfe = (): BuscaNfeContextDataProps => {
  return useContext(BuscaNfeContext);
};
